
import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import NavbarManager from './components/NavbarManager';
import { BrowserRouter as Router, Routes, Route }
  from 'react-router-dom';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Finished from './pages/Finished/Finished';
import NotFound from './pages/NotFound/NotFound';

function App() {
  return (
      <Router>
        <NavbarManager>
            <Navbar />
        </NavbarManager>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/finished' element={<Finished />} />
          <Route path='*' element={<NotFound />}/>
        </Routes>
      </Router>
  );
}

export default App;