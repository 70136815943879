import React, { Component } from 'react';
//import { useSearchParams } from 'react-router-dom';
import Form from "@rjsf/core";
import validator from '@rjsf/validator-ajv8';
import logo from "../../resources/images/Logo-White-Small.png";
// import schema from "../../schema.json";
// import uiSchema from "../../uiSchema.json";

class Home extends Component {
    schema = {
        type: "object",
        properties: {
            firstName: {
                type: "string",
                title: "First Name"
            },
            lastName: {
                type: "string",
                title: "Last Name"
            },
            email: {
                type: "string",
                title: "Email",
                minLength: 10
            },
            phone: {
                type: "string",
                title: "Phone",
                minLength: 10
            },
            postal: {
                type: "string",
                title: "Zip Code",
                minLength: 5
            },
            language: {
                type: "string",
                title: "Language",
                enum: ["en", "es"],
                enumNames: ["English", "Spanish"]
            },
        },
        required: [
            "firstName", "lastName", "email", "phone", "postal", "language"
        ]
    }

    uiSchema = {
        'ui:globalOptions': {
            label: false,
        },
        firstName: {
            "ui:autofocus": true,
            "ui:disabled": false,
            'ui:placeholder': 'First Name'
        },
        lastName: {
            "ui:disabled": false,
            'ui:placeholder': 'Last Name'
        },
        email: {
            "ui:disabled": false,
            'ui:placeholder': 'Email',
            'ui:options': {
                inputType: 'email',
                label: false,
            },
            'ui:style': { color: 'red' },
        },
        phone: {
            "ui:disabled": false,
            'ui:placeholder': 'Phone',
            'ui:options': {
                inputType: 'tel',
            },
        },
        postal: {
            "ui:disabled": false,
            'ui:placeholder': 'Zip Code'
        },
        language: {
            "ui:disabled": false,
            'ui:placeholder': 'Language'
        },
    }

    constructor(props) {
        super(props);
        let params = new URL(document.location.toString()).searchParams;
        let acct = params.get("acct");
        if (acct == null || acct === '') {
            acct = 'master';
        }
        this.state = {
            message: "",
            account: acct
        };
    }

    onSubmit = async ({formData}, e) => {
        e.preventDefault();
        console.log("Data submitted: ", formData);
        console.log("First Name: ", formData.firstName);

        try {
            //let [searchParams] = useSearchParams();
            //let acct = searchParams.get('acct');
            //let acct = window.location.href.split('/')[3];
            //let params = new URL(document.location.toString()).searchParams;
            //let acct = params.get("acct");
            //if (acct == null || acct === '') {
            //    acct = 'master';
            //}
            let acct = this.state.account;
            console.log("onSubmit(): acct = ", acct);
            let res = await fetch("https://api.workfromhome.zone/v1/leads", {
                method: "POST",
                headers: {
                    //'X-Api-Key': 'EDC7CD8B-FA38-40E6-867D-C713DD7FDBDB',
                    //'Access-Control-Allow-Origin': 'https://www.workfromhome.zone',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    accountId: acct,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    emailAddress: formData.email,
                    phoneNumber: formData.phone,
                    countryCode: "us",
                    postalCode: formData.postal,
                    languageCode: formData.language,
                    campaign: 'WorkFromHomeZone'
                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {
                //setName("");
                //setEmail("");
                this.setMessage("Your info was submitted!");
            } else {
                this.setMessage("Some error occurred!");
            }
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return (
            <div className="App">
                <div className='splash-logo'>
                    <img src={logo} alt='WorkFromHomeZone logo' />
                </div>
                <div>
                    <h2>Break away from the 9-to-5 grind!</h2>
                </div>
                <div>&nbsp;</div>
                <div>
                    <h2>Obtain financial freedom!</h2>
                </div>
                <div>&nbsp;</div>
                <div>
                    <h2>Set your own schedule and live life to the fullest!</h2>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>
                    <h2>We are offering a business opportunity to qualified applicants today!</h2>
                </div>
                <div>&nbsp;</div>
                <div>
                    <h2>Requirements:</h2>
                </div>
                <div>
                    <h4>Must be able to legally work in the United States.</h4>
                    <h4>Must be able to pass a background check.</h4>
                    <h4>Must be open to self-development.</h4>
                </div>
                <br />
                <h2 className="form-signin-heading">
                    For additional information, submit your info today:
                </h2>
                <div className="container" style={{width: "370px"}}>
                    <Form schema={this.schema} uiSchema={this.uiSchema} validator={validator} onSubmit={this.onSubmit}>
                        <div />
                        <br />
                        <button type="submit" className="btn btn-primary">Submit</button>
                        <div className="message">{this.message ? <p>{this.message}</p> : null}</div>
                    </Form>
                </div>
                <div>
                    <br />
                </div>
            </div>
        )
    }
}

export default Home;