import React, { Component } from 'react';

class Finished extends Component {

    render() {
        return (
            <div className="App">
                <br /><br />
                <div>
                    <h2>Thanks!</h2>
                </div>
                <div>&nbsp;</div>
                <div>
                    <h2>Your data has been submitted.</h2>
                </div>
                <div>&nbsp;</div>
                <div>
                    <h2>Someone will reach out to you in the next 24 hours!</h2>
                </div>
            </div>
        )
    }
}

export default Finished;