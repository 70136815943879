import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";

const NavbarManager = ({ children }) => {
    const location = useLocation();
    const [showNavbar, setShowNavbar] = useState(false);

    useEffect(() => {
        console.log("This is location: ", location);
        if (location.pathname === '/about') {
            setShowNavbar(false);
        } else {
            setShowNavbar(true);
        }
        // force the navbar to false
        setShowNavbar(false);
    }, [location]);

    return (
        <div>{showNavbar && children}</div>
    );
};

export default NavbarManager;