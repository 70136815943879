import React, {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";

const NotFound = () => {
    const url = window.location.href;
    const idx = url.lastIndexOf("/");
    const acct = url.substring(idx + 1);
    const [loading, setLoading] = useState(true);
    const [isGoodAccount, setIsGoodAccount] = useState(false);
    if (acct != null && acct !== '') {
        console.log("Account is: ", acct);
        // if we know the account then redirect to the main page
        //isGoodAccount = isValidAccount(acct);
    }

    /*async function isValidAccount(acct) {
        const url = "https://api.workfromhome.zone/v1/accounts/" + acct;
        try {
            const response = await fetch(url);
            const data = await response.json();
            console.log(data);
            if (response.ok) {
                return true;
            }
        } catch (error) {
            console.error(error);
        }
        return false;
    } */

    useEffect(() => {
        const url = "https://api.workfromhome.zone/v1/accounts/" + acct;
        console.log("URL: ", url);
        fetch(url)
            .then((response) => {
                if (response.ok) {
                    console.log("We found account: '", acct, "'!");
                    // Store the account in the session

                    // Redirect to the master account page
                    setIsGoodAccount(true);
                    setLoading(false);
                } else {
                    console.log("Failed to fetch data for account: '", acct, "'!");
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err.message);
                setLoading(false);
            });
    }, [acct]);

    if (loading) {
        return <div style={{'margin-left': '10px', 'margin-top': '10px'}}>Loading...</div>;
    } else {
        if (isGoodAccount) {
            let navigateUrl = "/";
            if (acct != null && acct !== '') {
                navigateUrl = "/?acct=" + acct;
            }
            return <Navigate to={navigateUrl} />
        } else {
            return (
                <div style={{'margin-left': '10px', 'margin-top': '10px'}}>
                    <h1>
                        The page you were looking for was not found!
                    </h1>
                    <div>
                        You are currently accessing <b>{url}</b>
                    </div>
                    <div>
                        Unknown Account: {acct}
                    </div>
                </div>
            );
        }
    }
};

export default NotFound;